/* eslint-disable react-hooks/exhaustive-deps */
import {DownloadOutlined, PlusOutlined} from '@ant-design/icons'
import {FC, useEffect, useState} from 'react'
import {useMutation} from 'react-query'
import {initialQueryState, useDebounce} from '../../../../../../_metronic/helpers'
import {BtnRefetch} from '../../../../../../components'
import {useAuth} from '../../../../../modules/auth/core/Auth'
import {ExportDeviceDisplayInfor} from '../../core/_requests'
import {useListViewCamera} from '../../core/ListViewProviderCamera'
import {useQueryRequest} from '../../core/QueryRequestProviderCamera'
import {useQueryResponse, useQueryResponseData} from '../../core/QueryResponseProviderCamera'
import CameraEditModalForm from '../../camera-device-edit-modal/CameraEditModalForm'

type Props = {
  changeTab: any
}
const CameraInfoListSearchComponent: FC<Props> = ({changeTab}) => {
  const {auth, addressD, setAddressD} = useAuth()
  const data = useQueryResponseData()
  const {setItemIdForUpdateCamera} = useListViewCamera()

  const {refetch} = useQueryResponse()
  const {state, updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>(addressD || '')
  const [isLoading, setIsLoading] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setItemIdForUpdateCamera('0')
    // setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({
          search: debouncedSearchTerm.trim(),
          search2: debouncedSearchTerm.trim(),
          ...initialQueryState,
        })
      }
      return () => {
        setAddressD('')
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  const Export = () => {
    setIsLoading(true)
    exportDevice2.mutateAsync()
  }

  const exportDevice2 = useMutation(() => ExportDeviceDisplayInfor(auth?.configs, state), {
    onSuccess: (response) => {
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Danhsachbangdientu.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      setIsLoading(false)
    },
  })

  return (
    <>
      <BtnRefetch refetch={refetch} />

      <button
        type='button'
        className='btn btn-primary me-3'
        onClick={Export}
        disabled={data.length === 0}
      >
        <DownloadOutlined />
        Xuất dữ liệu
      </button>
      <button type='button' className='btn btn-primary me-3' onClick={showModal}>
        <PlusOutlined />
        Thêm mới
      </button>

      {isModalOpen && <CameraEditModalForm handleCancel={handleCancel} isModalOpen={isModalOpen} />}
    </>
  )
}

export {CameraInfoListSearchComponent}
