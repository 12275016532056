import { useQuery } from 'react-query'
import { DeviceInfoModalForm } from './DeviceInfoModalForm'
import { QUERIES } from '../../../../../_metronic/helpers'
import { useListViewCamera} from '../core/ListViewProviderCamera'
import { getDeviceById } from '../core/_requests'
import { useAuth } from '../../../../modules/auth/core/Auth'
import { Loading } from '../../../../../_metronic/layout/components/loading/Loading'
import Swal from 'sweetalert2'
import { useQueryResponse } from '../core/QueryResponseProviderCamera'

const DeviceInfoModalFormWrapper = () => {
  const { auth } = useAuth()

  const { itemIdForCamera: itemIdForLed, setItemIdForCamera: setItemIdForLed } = useListViewCamera()
  const { refetch } = useQueryResponse()
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForLed({})
  }
  const {
    isLoading,
    data: device,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForLed}`,
    () => {
      return getDeviceById(auth?.configs, itemIdForLed.Id || '')
    },
    {
      cacheTime: 0,
      enabled: true,
      onError: (err: any) => {
        Swal.fire({
          icon: 'error',
          title: 'Không tìm thấy dữ liệu',
          text: ' ',
          confirmButtonText: 'Đóng lại',
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            cancel(true)
          }
        })
      },
    }
  )

  if (device)
    return <DeviceInfoModalForm isLoading={isLoading} device={device || { Id: "0" }} />
  return <Loading />
}

export { DeviceInfoModalFormWrapper }
