import {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers/components/KTSVG'
import {Role, useAuth} from '../../../../modules/auth'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import Transfer, {TransferDirection} from 'antd/lib/transfer'

type Props = {
  roles: Array<Role> // Ds quyền của user
}

interface RecordType {
  key: string
  title: string
  description: string
  chosen: boolean
}

const RoleModalForm: FC<Props> = ({roles}) => {
  const {setSelectedObject, setSelectedRoles} = useListView()
  const {currentUser} = useAuth()
  const {refetch} = useQueryResponse()
  const [dataSource] = useState<Array<any>>(
    currentUser?.Roles.map((item) => ({
      key: item.Value,
      title: item.Name,
    })) || []
  )
  const [targetKeys, setTargetKeys] = useState<any>(
    roles.map((item) => ({
      key: item.Value,
      title: item.Name,
    })) || []
  )
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])

  useEffect(() => {
    setTargetKeys(roles.map((item) => item.Value))
  }, [roles])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setSelectedObject({})
  }

  const onChange = (nextTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) => {
    setTargetKeys(nextTargetKeys)
  }

  const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
  }

  const save = () => {
    const result = currentUser?.Roles.filter((permission) => targetKeys.includes(permission.Value))
    setSelectedRoles(result || [])
    cancel()
  }

  const filterOption = (inputValue: string, option: RecordType) => {
    // return option.description.indexOf(inputValue) > -1;
    return option.title.toLocaleLowerCase().includes((inputValue.trim() || '').toLocaleLowerCase())
  }

  return (
    <>
      <form id='kt_modal_group_form' className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column'
          id='kt_modal_group_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <Transfer
            dataSource={dataSource}
            titles={['Quyền chưa có', 'Quyền đang có']}
            showSearch
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={onChange}
            onSelectChange={onSelectChange}
            // onScroll={onScroll}
            render={(item) => item.title}
            filterOption={filterOption}
            listStyle={{
              width: '100%',
              height: '500px',
            }}
            locale={{
              searchPlaceholder: 'Tìm kiếm',
              itemsUnit: 'quyền',
              selectInvert: 'Đảo ngược trang hiện tại',
              selectAll: 'Chọn tất cả giá trị',
            }}
          />

          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-5'>
          <button
            type='button'
            onClick={() => cancel()}
            className='btn btn-outline-primary me-3 cancel'
            data-kt-users-modal-action='cancel'
          >
            Trở lại
          </button>

          <button type='button' className='btn btn-primary' onClick={() => save()}>
            <span className='indicator-label'>Cập nhật</span>
          </button>
        </div>
        {/* end::Actions */}
      </form>
    </>
  )
}

export {RoleModalForm}
