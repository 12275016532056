// import { CameraEditModalForm } from './CameraEditModalForm'
import {useAuth} from '../../../../modules/auth/core/Auth'
import {useListViewCamera} from '../core/ListViewProviderCamera'
import {useQueryResponse} from '../core/QueryResponseProviderCamera'
import CameraEditModalForm from './CameraEditModalForm'

const CameraEditModalFormWrapper = () => {
  const {auth} = useAuth()

  const {itemIdForUpdateCamera: itemIdForUpdateLed, setItemIdForUpdateCamera} = useListViewCamera()
  const {refetch} = useQueryResponse()
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdateCamera('')
  }

  return <CameraEditModalForm isModalOpen={true} handleCancel={cancel} id='' />
}

export {CameraEditModalFormWrapper}
