//import React, {useState} from 'react'
import axios, {AxiosResponse} from 'axios'
//import { Query } from 'react-query'
import {QueryState, Response} from '../../../../../_metronic/helpers'
import {
  ContentFieldQueryResponse,
  DeviceDisplay,
  DeviceInfo,
  DeviceInfoQueryResponse,
} from './_models'
import {UserModel} from '../../../../modules/auth'
import {DynamicConfig} from '../../../../../configuration/config'

const deviceInfo = '/DeviceInfo'
const CONTENTCATEGORIES = '/ContentCategories'

const getAllDeviceInfo = (
  configs: DynamicConfig,
  state: QueryState,
  currentUser?: UserModel
): Promise<DeviceInfoQueryResponse> => {
  return axios
    .post(
      `${configs.apiUrl}${deviceInfo}/get-listDeviceInfoByFilter`,
      {
        Info: state.search2 || '',
        State: parseInt(state.tmp || '') ?? null,
        GroupIds: currentUser?.DeviceGroupIds,
      },
      {
        headers: {
          currentPage: state.currentPage,
          pageSize: state.pageSize,
        },
      }
    )
    .then((d: AxiosResponse<DeviceInfoQueryResponse>) => d.data)
}

const getlistDisplayDeviceInfoByFilter = (
  configs: DynamicConfig,
  state: QueryState
): Promise<DeviceInfoQueryResponse> | undefined => {
  if (state.filter == undefined) return undefined
  return axios
    .post(`${configs.apiUrl}${deviceInfo}/get-listDisplayDeviceInfoByFilter`, state.filter, {
      headers: {
        currentPage: state.currentPage,
        pageSize: state.pageSize,
      },
    })
    .then((d: AxiosResponse<DeviceInfoQueryResponse>) => d.data)
}

const getDeviceById = (configs: DynamicConfig, id: string): Promise<DeviceInfo> => {
  return axios
    .post(`${configs.apiUrl}${deviceInfo}/get-deviceById`, {Id: id})
    .then((response: AxiosResponse<DeviceInfo>) => response.data)
}

const controlDeviceInfo = (configs: DynamicConfig, Imei: string, Commnad: string): Promise<any> => {
  return axios
    .post(`${configs.apiUrl}${deviceInfo}/control-controlDeviceInfo`, {
      Imei: Imei,
      Commnad: Commnad,
    })
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const checkDeleteDevice = (configs: DynamicConfig, Id: string, onError: any): Promise<void> => {
  return axios
    .put(`${configs.apiUrl}${deviceInfo}/check-delete-deviceInfo`, {Id: Id})
    .then(() => {})
    .catch((e) => onError(Id, e.response.data))
}

const deleteDeviceInfo = (configs: DynamicConfig, id: string): Promise<void> => {
  return axios.put(`${configs.apiUrl}${deviceInfo}/delete-deviceInfo`, {Id: id}).then(() => {})
}

const ExportDeviceInfor = (
  configs: DynamicConfig,
  state: QueryState,
  currentUser?: UserModel
): Promise<Blob> => {
  return axios
    .post(
      `${configs.apiUrl}${deviceInfo}/export-listDeviceInfo`,
      {
        Info: state.search2 || '',
        State: parseInt(state.tmp || '') ?? null,
        GroupIds: currentUser?.DeviceGroupIds,
      },
      {responseType: 'blob'}
    )
    .then((d: AxiosResponse<Blob>) => d.data)
}

const ExportDeviceDisplayInfor = (configs: DynamicConfig, state: QueryState): Promise<Blob> => {
  return axios
    .post(`${configs.apiUrl}${deviceInfo}/export-listDisplayDeviceInfo`, state.filter, {
      responseType: 'blob',
    })
    .then((d: AxiosResponse<Blob>) => d.data)
}

const getcontentFields = (configs: DynamicConfig): Promise<ContentFieldQueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${CONTENTCATEGORIES}/get-contentFields`, {
      headers: {
        currentPage: 1,
        pageSize: 100,
      },
    })
    .then((d: AxiosResponse<ContentFieldQueryResponse>) => d.data)
}

const getDeviceDisplayActive = (configs: DynamicConfig, id: string): Promise<DeviceDisplay> => {
  return axios
    .post(`${configs.apiUrl}${deviceInfo}/get-deviceDisplayActive`, {Id: id})
    .then((response: AxiosResponse<DeviceDisplay>) => response.data)
}

const updateDeviceDisplayActive = (
  configs: DynamicConfig,
  value: DeviceDisplay,
  displayTimeStart: string,
  displayTimeStop: string
): Promise<DeviceDisplay> => {
  return axios
    .post(`${configs.apiUrl}${deviceInfo}/update-deviceDisplayActive`, {
      Id: value.Id,
      DisplayTimeStart: displayTimeStart,
      DisplayTimeStop: displayTimeStop,
      IsActive: value.IsActive,
    })
    .then((response: AxiosResponse<DeviceDisplay>) => response.data)
}

export {
  getlistDisplayDeviceInfoByFilter,
  getAllDeviceInfo,
  checkDeleteDevice,
  getDeviceById,
  deleteDeviceInfo,
  controlDeviceInfo,
  ExportDeviceInfor,
  ExportDeviceDisplayInfor,
  getcontentFields,
  getDeviceDisplayActive,
  updateDeviceDisplayActive,
}
